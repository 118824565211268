import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import JsPDF from 'jspdf';
import QRCode from 'qrcode';
import dayjs from 'dayjs';
import cx from 'classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import { Col, Row, Result, Popover, Space } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { PrinterOutlined, PaperClipOutlined } from '@ant-design/icons';
import styles from './memberCertificate.module.css';
import { getUserCertificate } from '../../services/services';
import download from '../../assets/download.png';
import { CompanyInterface } from '../../models/companyInterface';
import config from '../../config';
import { UserCategory } from '../../consts/UserCategoryEnum';
import pulpCertificate from '../../assets/pulpCertificate.png';
import pulpCertificateLogo from '../../assets/pulpCertificateLogo.png';

const MemberCertificatePulp = (): ReactElement => {
  const { t } = useTranslation('common');
  const [qr, setQr] = useState<string | null>(null);
  const [companyData, setCompanyData] = useState<CompanyInterface>();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  useEffect(() => {
    const setCompany = async (id: string): Promise<void> => {
      const userCertificate = await getUserCertificate(id);
      if (userCertificate === undefined) return;
      if (userCertificate?.registrationDate !== undefined)
        userCertificate.registrationDate = dayjs(
          userCertificate.registrationDate,
          config.dateFormat.universalFormat,
        ).format(config.dateFormat.certificate);
      if (userCertificate?.membershipDue !== undefined)
        userCertificate.membershipDue = dayjs(
          userCertificate.membershipDue,
          config.dateFormat.universalFormat,
        ).format(config.dateFormat.certificate);
      setCompanyData(userCertificate);
    };
    if (id !== undefined) setCompany(id);
  }, [id]);

  const handleDownload = async (scale: number): Promise<void> => {
    const doc = new JsPDF('l', 'mm', 'a4');
    const elementHTML = document.querySelector('#pdfContent') as HTMLElement;

    const pdfWidth = 297; // A4 width in mm
    const canvas = await html2canvas(elementHTML, {
      scale,
      useCORS: true,
    });

    const imgData = canvas.toDataURL('image/png');
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    const ratio = contentHeight / contentWidth;

    const a4Height = pdfWidth * ratio;

    doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, a4Height);
    doc.save('member-certificate.pdf');
  };

  useEffect(() => {
    QRCode.toDataURL(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }${config.routes.memberCertificate.replace(':id', id as string)}?status=1`,
    )
      .then((url) => {
        setQr(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  const longCompanyName = useMemo(() => {
    if (companyData?.companyName && companyData?.companyName?.length > 30) return true;
    return false;
  }, [companyData?.companyName]);

  const isValid =
    companyData &&
    dayjs(companyData?.membershipDue, config.dateFormat.certificate).format('YYYY.MM.DD') >=
      dayjs().format('YYYY.MM.DD');

  return (
    <div className={styles.container}>
      {status === '1' ? (
        companyData && (
          <Result
            status={isValid ? 'success' : 'warning'}
            title={t(`misc.${isValid ? 'valid' : 'notValid'}CertificateMessage`)
              .replace(':company', companyData?.companyName)
              .replace(
                ':date',
                dayjs(companyData?.membershipDue, config.dateFormat.certificate).format(
                  'YYYY.MM.DD',
                ),
              )}
          />
        )
      ) : (
        <>
          <Row justify={'end'} className={styles.downloadBtnWrap}>
            <Col>
              <Popover
                content={
                  <Space direction='vertical' className={styles.popoverDownload}>
                    <button
                      onClick={() => {
                        handleDownload(2);
                      }}
                      className={styles.download}
                    >
                      <Row justify={'space-around'} align='middle' gutter={5}>
                        <Col>
                          <PaperClipOutlined />
                        </Col>
                        <Col>{t('misc.webQuality')}</Col>
                      </Row>
                    </button>
                    <button
                      onClick={() => {
                        handleDownload(4);
                      }}
                      className={styles.download}
                    >
                      <Row justify={'space-around'} align='middle' gutter={5}>
                        <Col>
                          <PrinterOutlined />
                        </Col>
                        <Col>{t('misc.printQuality')}</Col>
                      </Row>
                    </button>
                  </Space>
                }
                trigger='click'
                placement='bottomRight'
              >
                <button className={styles.download}>
                  <Row justify={'space-around'} align='middle' gutter={5}>
                    <Col>
                      <img src={download} className={styles.downloadImg} alt='' />
                    </Col>
                    <Col>{t('misc.downloadCertificate')}</Col>
                  </Row>
                </button>
              </Popover>
            </Col>
          </Row>
          <div className={styles.pdfContent} id='pdfContent'>
            <div className={styles.content}>
              <Row justify='space-between'>
                <Col className={styles.title}>
                  {t('misc.certificate')} {t('misc.of')}{' '}
                  {companyData?.userCategory === UserCategory.MEMBER
                    ? t('misc.membership')
                    : companyData?.userCategory === UserCategory.PARTNER && t('misc.partnership')}
                </Col>
              </Row>
              <Row>
                <Col span={24} className={styles.text}>
                  {companyData?.userCategory === UserCategory.MEMBER && (
                    <Trans
                      i18nKey='common:certificatePulp.description'
                      components={[
                        <div key={0} className={styles.header} />,
                        <div
                          className={cx(styles.companyName, longCompanyName && styles.longName)}
                          key={1}
                        />,

                        <div key={2} className={styles.description} />,
                        <div key={3} className={styles.description} />,
                        <div key={4} className={styles.description} />,
                      ]}
                      values={{
                        companyName: companyData?.companyName,
                      }}
                    />
                  )}

                  <Row className={styles.signatureWrapper} justify='center'>
                    <Row justify='space-between'>
                      <Col className={styles.pulpQr}>
                        <Row className={styles.qr} justify='center' align={'middle'}>
                          <Col>{qr !== null && <img src={qr} className={styles.qrImg} />}</Col>
                        </Row>
                        <div className={styles.qrText}>{t('certificatePulp.scanToValidate')}</div>
                      </Col>
                    </Row>
                    <Row justify='center'>
                      <Row>
                        <Col className={styles.dateText}>
                          {t('misc.issueDate')}: <b>{companyData?.registrationDate as string}</b>
                        </Col>
                      </Row>
                      <Row>
                        <Col className={styles.dateText}>
                          {t('misc.validTill')}: <b>{companyData?.membershipDue as string}</b>
                        </Col>
                      </Row>
                    </Row>
                  </Row>

                  <img className={styles.logo} src={pulpCertificateLogo} alt='certificate-logo' />
                </Col>
              </Row>
            </div>

            <img className={styles.background} src={pulpCertificate} alt='certificate-background' />
          </div>
        </>
      )}
    </div>
  );
};

export default MemberCertificatePulp;
